import type { MetaArgs, MetaFunction } from '@remix-run/node';
import type { MetaDescriptor } from '@remix-run/react';

const generatePageTitle = ({
  matches,
  title,
}: Pick<MetaArgs, 'matches'> & { title: string }) => {
  const parentWithTitle = matches
    .flatMap((match) => match.meta ?? [])
    .findLast((meta) => 'title' in meta);
  return (
    parentWithTitle &&
    'title' in parentWithTitle &&
    parentWithTitle.title + ` | ${title}`
  );
};

export const generateMeta =
  ({
    title,
    extraMetaTags,
  }: {
    extraMetaTags?: MetaDescriptor[];
    title: string;
  }): MetaFunction =>
  ({ matches }) => {
    const parentMeta = matches.flatMap((match) => match.meta ?? []);
    const filteredParentMeta = parentMeta.filter((meta) => !('title' in meta));

    return [
      ...filteredParentMeta,
      {
        title: generatePageTitle({
          matches: matches,
          title,
        }),
      },
      ...(extraMetaTags ?? []),
    ];
  };
